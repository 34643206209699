import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const HowToGetAnAfro = () => (
  <Layout>
    <SEO
      title="How To Get An Afro"
      description="The only tips you need on how to get an afro! 1 Managing expectations. 2 What type of afro do you want? 3 How to do it. 4 Holding moisture. 5 Setting and holding. 6 Trimming. 7 Drying."
    />
    <h1>How To Get An Afro</h1>
    <h2 style={{color:"Orchid"}}>The ultimate list to get you the perfect afro</h2>
    <p>Growing an afro seems easy enough but if you miss a few important steps, you could slow your own progress or end up with problems that could mean starting all over again.</p>
    <p>Don’t worry though because everything you need is here so once you’ve finished, you can stop research and get started.</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/1wFIPZWl.jpg"
        alt="how to get an afro nahc"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@princearkman?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Prince Akachi</a></p>
    </div>
    <p>A well-kept afro is one of of the most beautiful sights there is. It can fill a person with confidence and is one of the original forms of ultimate self-expression. When I have my afro out, I call it my crown. I’m sure I’m not the only one and I’m also sure that I don’t need to convince you of the benefits, you must be here because you’ve already decided.</p>
    <p>Here are our tips on how to get an afro;</p>

    

    <h3>1. Managing expectations</h3>
    <p>It’s important to have a good idea of what to aim for. It’s largely dependent on your hair type. If you have tightly coiled hair, don’t expect a looser afro with large visible rings that bounce around when you walk. On the other hand, if you have looser curls, learn that your afro won’t defy gravity and will begin to hang down much sooner.</p>
    <p>Head over to google images, Instagram, Pinterest or wherever you have to go and search for afro types that most suits your own hair. If you’ve never grown your hair in adulthood before then look at your parents, family members, or younger pictures of yourself.</p>

    <h3>2. What type of afro do you want?</h3>
    <p>Some of us with tighter coiled hair have the option of what type of afro to have. Messy or neat. An example that comes to mind is Trevor Noah, he switches between a tidy afro and a messy afro regularly.</p>
    <p>The messy afro has visible tight curls or uneven in level. Parts of the hair are higher and lower all over the head but it still looks good because the pattern is uniform. No matted patches or flat sides like you’ve just woken up. It looks intentional and styled. A neat afro looks round and almost like a ball on your head. It works better at shorter lengths. I personally prefer the messy afro because it is much easier to maintain and suits me better but they both look good.</p>

    <h3>3. How to style an afro</h3>
    <h4 style={{fontSize:"1.2rem", color:"Orchid"}}>Messy Afro</h4>
    <p>Comb out your hair when you're washing it. Don’t comb it again after you are done and towel dry it. Finger comb your hair if you need to when you are adding conditioner. This helps maintain and emphasise your natural curls that the combing process can reduce when the hair is not wet. It's because combing separates the hair strands and groups of strands is what creates defined curls.</p>
    <h4 style={{fontSize:"1.2rem", color:"Orchid"}}>Tidy Afro</h4>
    <p>The opposite to above pretty much. Do your usual washing and conditioning process then comb out at the end. If your hair is uneven, comb out your hair as much as you can to get rid of any pits then look in the mirror and level as best you can with your hands by patting down. Not too much because you will end combing it back out again.</p>

    <h3>4. Adding Moisture</h3>
    <p>Maintaining moisture in your hair is one of the most crucial steps. An afro is the opposite of a protective style so your hair is as exposed to the elements as it ever will be. This is why you cannot miss this step. A good conditioning routine should last all day and require topping up a few times a week when necessary. If your hair always ends up dry and brittle, something is wrong and your beautiful afro won’t last long. You can experiment or read one of our guides by browsing the site.</p>

    <h3>5. Setting and holding</h3>
    <p>If your hair changes shape and sags or hangs lower as the day goes on, you can try setting or holding spray. The best ones double up as light conditioners and don’t leave a white coating on your hair. They also smell nice too. Again, you might have to look around and experiment or read one of our guides.</p>

    <h3>6. Trimming your afro</h3>
    <p>Remember how I said if your hair is uneven you would have to pat the higher bits down with your hands to make it level? Well a real solution to this and one that a lot of people are scared of is to get your hair trimmed. Go to a trained professional and get them to level your hair. It’s a straight forward process but one that takes skill where they comb it out and trim it appropriately.</p>
    <p>Trimming your hair gives the added benefit of improving hair health. All those split and damaged ends that you might have had are gone and will allow your hair to grow longer much more quickly because it isn’t breaking off anywhere as easily.</p>

    <h3>7. The drying process</h3>
    <p>Depending on the texture of your hair, you have three options. Air dry, blow dry or diffuse. Each one has its benefits and everyone will have their own favourite.</p>
    <h4 style={{fontSize:"1.2rem", color:"Orchid"}}>Air dry</h4>
    <p>This is a good method if you want a messy look or your hair is at a length you are happy with - even with shrinkage. Follow you regular routine and comb out your hair while you add conditioner. Then style your hair neat or messy and go about your day as usual. You can use a towel to remove excess water but dab the hair and don’t scrub. Scrubbing you head can damage your hair and tangle it causing problems later.</p>
    <p>Air drying is good because it is the least stressful method of drying and it is the best and retaining the natural texture of your hair. The downside is that you might need to detangle more regularly than other methods.</p>
    <h4 style={{fontSize:"1.2rem", color:"Orchid"}}>Blow dry</h4>
    <p>If you have tightly coiled hair and it’s quite short, blow drying could be a good idea if you want the appearance of extra length. Blow dry the hair on low heat to avoid damage while combing it out. You can do this with a pick attachment of blow with one arm and use a pick to comb with the other. Also make sure to monitor moisture levels in your hair so it doesn’t dry out. Use a spray bottle or add a good amount of conditioner and oil before you start.</p>
    <p>Blow drying an afro takes time patience to get used. You might find that some parts of your hair got stretched more than others so try your best and learn for next time.</p>
    <h4 style={{fontSize:"1.2rem", color:"Orchid"}}>Diffuser</h4>
    <p>If you haven’t used a diffuser before, it’s an attachment that goes on your hair drier. They are a flat circle shape with points that are designer to push air through scrunched hair. It is a whole different process to using a hair dryer with a pick attachment and gives different results.</p>
    <p>I suggest using the diffuser if you have looser curls that stretch out easily. Using a diffuser will help set your curls in a tighter position so they a more likely to hold throughout the day.</p>


    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default HowToGetAnAfro
